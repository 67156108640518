<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title class="font-weight-bold mb-3 mt-10"
        >تفاصيل الملاحظة</v-card-title
      >
      <v-row v-if="edit_loader">
        <v-col v-for="(a, i) in 3" :key="i" cols="6" md="3">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-form ref="form" v-if="!edit_loader">
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in $store.getters.langs"
            :key="i"
          >
            {{ lang.lang_name }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(lang, i) in $store.getters.langs" :key="i">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  :rules="[(v) => !!v || 'حقل مطلوب']"
                  v-model="note.name[lang.locale]"
                  solo
                >
                  <template slot="label">
                    <strong class="red--text" v-if="tabs == 0">*</strong>
                    الملاحظة
                  </template>
                </v-text-field>
              </v-col>
              <!--/ col  company name-->

              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-file-input
                  v-model="note.image"
                  prepend-icon=""
                  prepend-inner-icon="attach_file"
                  solo
                  label="صورة"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <!--/ row -->
          </v-tab-item>
        </v-tabs-items>
        <!--/ col image-->
        <v-btn
          :loading="save_loader"
          :disabled="save_loader"
          color="primary"
          class="save"
          width="250"
          height="50"
          @click="save()"
          >حفظ</v-btn
        >
      </v-form>
      <!-- / form -->
    </v-card>
    <!--/ card -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حفظ اللاحظة بنجاح</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_error">
      <p>برجاء مراجعة البيانات</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">cancel</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "New_category",
  data() {
    return {
      tabs: 0,
      edit_loader: false,
      save_loader: false,
      snackbar_success: false,
      snackbar_error: false,
      note: {
        name:{},
        image: null,
      },
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;
        const formData = new FormData();

        Object.entries(this.note).forEach((e) => {
          if (e[0] != "name") {
            formData.append(e[0], e[1]);
          } else {
            Object.entries(this.note.name).forEach((s) => {
              formData.append(`name[${s[0]}]`, s[1]);
            });
          }
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `notes/${this.$route.params.id}`
                : `notes`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.snackbar_success = true;
            this.save_loader = false;
            setTimeout(() => {
              this.$router.push("/Notes");
            }, 1500);
          })
          .catch((err) => {
            this.snackbar_error = true;
            this.save_loader = false;
          });
      }
    },
    edit_note() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `notes/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          let data = res.data.data;
          data.note_name.forEach((e) => {
            this.note.name[e.locale] = e.name
          })
          this.edit_loader = false;
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.edit_loader = true;
      this.edit_note();
    }
  },
};
</script>

<style lang="scss" scoped>
.save {
  font-size: 20px;
}
::v-deep {
  .v-skeleton-loader__heading {
    width: 80% !important;
    height: 40px;
  }
}
</style>
